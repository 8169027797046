<template>
  <Transition name="shrink">
    <div v-if="!bannerDismissed" class="py-4 sm:py-6 w-full bg-black-800 text-white text-center relative">
      <i18n-t tag="p" class="text-white font-medium text-lg"
              keypath="appsumo.review.banner">
        <template #site>
          <a href="https://appsumo.com/products/soundmadeseen/" target="_blank" @click="dismiss" class="font-bold underline">
            {{ $t('appsumo.review.link') }}
          </a>
        </template>
      </i18n-t>
      <button @click="dismiss" class="absolute right-2 md:right-4 top-1/2 -translate-y-1/2 text-white">
        <img src="~/assets/img/icons/close-white.svg" :alt="$t('close')" class="w-6 h-6" />
      </button>
    </div>
  </Transition>
</template>

<script setup>
const bannerDismissed = useCookie('asrb-dismissed', {
  default: () => {
    return false;
  },
  maxAge: 365 * 24 * 60 * 60,
})

const dismiss = () => {
  bannerDismissed.value = true;
}

</script>

<style scoped>

</style>